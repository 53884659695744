body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#units{
  background: #000;
  color: #fff;
  margin: 20px 0;
  font-size:40px;
}
#units h1{
  font-size:60px;
}
#units h2{
  color: #03ff03;
  font-size:40px;
}

h4{
  font-size:40px !important;
  color: #140174;
}

.buttons{
  font-size:44px;
  margin: 0 0 40px 0;
}

.btn{
  font-size:40px !important;
  width:100px;
}

#info{
  background: #666;
  font-size:19px;
  color:#fffcf0;
  padding: 20px 0px;
}